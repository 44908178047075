<template>
  <div
    v-if="loading"
    class="text-center"
  >
    <ProgressSpinner />
  </div>
  <div v-else>
    <SumHeaderTable
      :value="keywords"
      :global-filter-fields="['query']"
      :columns="columns"
      :sums="keywordSums"
    />
  </div>
</template>

<script>
import { markRaw, defineAsyncComponent } from 'vue';
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';

import A2CAClient from '../../../../../api/a2caAuthorizedClient';
import { ReportType, REPORT_DATE_FORMAT } from '../../../../../constants/reportConstants';

import SumHeaderTable from '../../../../SumHeaderTable.vue';
import generateCommonColumns from '../../../../../utils/tableUtils';
import { formatBid, currencySymbolFromCode } from '../../../../../utils/formatUtils';

export default {
  components: { SumHeaderTable },
  inject: ['query'],
  props: {
    selectedProfile: {
      type: Object,
      required: true,
    },
    dateRange: {
      type: Array,
      required: true,
    },
    campaignId: {
      type: String,
      required: true,
    },
    adGroupId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      keywords: null,
      keywordSums: null,
      columns: [
        {
          id: 'searchTermQuery',
          field: 'keyword',
          header: 'Customer Search Term',
          sortable: true,
          numeric: false,
          decimal: false,
          width: '300px',
          content: {
            center: false,
            to: null,
            badge: null,
            format: null,
          },
        },
        {
          id: 'searchTermKeywords',
          field: 'keywordText',
          header: 'Keywords',
          sortable: false,
          numeric: false,
          decimal: false,
          width: '350px',
          content: {
            center: false,
            to: null,
            badge: null,
            format: null,
            slot: {
              component: markRaw(defineAsyncComponent(() => import('./SearchtermKeywords.vue'))),
              props: (data) => ({
                keywordText: data.keywordText,
                keyword: data,
                bid: data.bid,
                currencySymbol: currencySymbolFromCode(this.$store.state.auth.selectedProfile.currencyCode),
              }),
            },
          },
        },
        {
          id: 'searchAddedAs',
          field: 'addedAs',
          header: 'Added As',
          sortable: true,
          numeric: false,
          decimal: false,
          width: '120px',
          content: {
            center: false,
            to: null,
            badge: null,
            format: (data) => (data?.keywordText ? 'Keyword' : 'Target'),
          },
        },
        {
          id: 'defaultBid',
          field: 'bid',
          header: 'Default Bid',
          sortable: true,
          numeric: false,
          decimal: false,
          width: '120px',
          content: {
            center: true,
            to: null,
            badge: null,
            remove: '$',
            format: (data) => (data?.bid ? formatBid(data, this.$store.state.auth.selectedProfile.currencyCode) : 'No bid data'),
          },
        },
        ...generateCommonColumns('adGroups', () => this.royalties, () => this.$store.state.auth.selectedProfile),
      ],
    };
  },
  computed: {
    ...mapGetters(['royalties']),
  },
  watch: {
    dateRange(range) {
      if (range[1] !== null) {
        this.fetchAdGroups();
      }
    },
    selectedProfile: {
      handler() {
        this.fetchAdGroups();
      },
      immediate: true,
    },
  },
  methods: {
    async fetchAdGroups() {
      this.loading = true;
      this.keywords = null;
      const client = new A2CAClient(this.query);
      const keywordReportsRequest = client.getReports(
        this.selectedProfile.profileId,
        this.selectedProfile.region,
        ReportType.KEYWORDS,
        dayjs(this.dateRange[0]).format(REPORT_DATE_FORMAT), dayjs(this.dateRange[1]).format(REPORT_DATE_FORMAT),
        this.adGroupId,
        ReportType.AD_GROUPS,
        this.campaignId,
      );

      const targetReportsRequest = client.getReports(
        this.selectedProfile.profileId,
        this.selectedProfile.region,
        ReportType.TARGETS,
        dayjs(this.dateRange[0]).format(REPORT_DATE_FORMAT), dayjs(this.dateRange[1]).format(REPORT_DATE_FORMAT),
        this.adGroupId,
        ReportType.AD_GROUPS,
        this.campaignId,
      );

      const keywordsRequest = client.getKeywords(this.selectedProfile.profileId,
        this.selectedProfile.region, this.adGroupId);

      const targetsRequest = client.getTargets(this.selectedProfile.profileId,
        this.selectedProfile.region, this.adGroupId);

      const [keywordReportsResponse, keywords, targetReportsResponse, targets] = await Promise.all([keywordReportsRequest, keywordsRequest, targetReportsRequest, targetsRequest]);

      const { reportData } = keywordReportsResponse.parsedReport;
      this.keywordSums = keywordReportsResponse.aggregation;

      const keywordsData = reportData.map((report) => {
        const apiKeyword = keywords.find((keyword) => keyword.keywordId === report.keywordId);
        return {
          ...report,
          ...apiKeyword,
        };
      });

      const targetData = targetReportsResponse.parsedReport.reportData.map((report) => {
        const apiTarget = targets.find((target) => target.targetId === report.targetId);
        return {
          ...report,
          apiTarget,
        };
      });

      if (keywordsData.length === 0) {
        this.keywords = targetData;
      } else {
        this.keywords = keywordsData;
      }

      console.table(this.keywords);

      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../../../assets/styles/dashboard/campaigns.scss";
</style>
